<template>
    <auth-layout :title="$t('auth.password.forgot.title')" :subtitle="$t('auth.password.forgot.subtitle')">
        <v-form-group :label="$t('label.email')" :v="v$.form.email">
            <v-form-input v-model="form.email" type="email" autocomplete="username" />
        </v-form-group>

        <template #footer>
            <v-button type="submit" variant="primary" class="w-full" :loading="loading" @click="handleLogin">
                {{ $t('action.continue') }}
            </v-button>
        </template>
    </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
    setup () {
        return { v$: useVuelidate() }
    },

    data: () => ({
        loading: false,
        form: {
            email: null,
        }
    }),

    validations: () => ({
        form: {
            email: { required, email },
        }
    }),

    methods: {
		...mapActions('auth', [
            'forgotPassword'
		]),

        handleLogin (event) {
            event.preventDefault();

			this.v$.form.$touch();

            if (this.v$.form.$invalid) return;

            this.loading = true;

			this.forgotPassword(this.form).finally(() => {
                this.loading = false;
            });
		},
    }
};
</script>
